import React, { ReactElement, useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import loadable from '@loadable/component'
import { ImageDataLike, StaticImage } from 'gatsby-plugin-image'
import './Main.scss'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import Slider from 'react-slick'
import TestimonialList from '../../components/TestimonialList/TestimonialList'
import PropertyH1 from '../../assets/images/H&L 1.jpg'
import PropertyH2 from '../../assets/images/H&L 2.jpg'
import Condo1 from '../../assets/images/Condo 1.jpg'
import Condo2 from '../../assets/images/Condo 2.jpg'
import Seo from '../../components/Seo/Seo'
import CustomDiv from '../../hooks/ClosableDiv/Closable'
import AccessAyalaModal from '../../assets/images/access-ayala/Popup A Green Copy.png'

const Layout = loadable(() => import('../../components/Layout/Layout'))
const Banner = loadable(() => import('../../components/Banner/Banner'))

const LinkButton = loadable(() =>
  import('../../components/LinkButton/LinkButton'),
)

type ImageType = {
  url: string
  description: string
  featured: boolean
}

type TowerTypes = {
  name: string
  overviewDetails?: {
    propertyStatus: string
    propertyDetails: string
    propertyTitle: string
    imagesGallery: [
      {
        url: string
        featured: boolean
        featuredImage: ImageDataLike
      },
    ]
  }
}

type ContentTypes = {
  category: string
  title: string
  shortDescription: string
  subtitle: string
  id: string
  image?: {
    url: string
  }
}

type BannerTypes = {
  id: string
  image: string
  title: string
  optimizedFeaturedImg: ImageDataLike
}

export type PropertyTypes = {
  id: string
  originalId: string
  name: string
  overviewDefault: string
  slug: string
  image: ImageType
  featured?: boolean
  projectName: string
  propertyPrice: string
  propertySizeFloorArea: string
  propertySizeLotArea: string
  propertyType: string
  propertyPriceRangeHighest: string
  propertyPriceRangeLowest: string
  location: string
  towers: TowerTypes[]
}

type awardPropType = {
  year: string
  award: string
  organization: string
  image: string
  project: string
}

type MainPropTypes = {
  data: {
    allContent: { nodes: ContentTypes[] }
    allProperty: { nodes: PropertyTypes[] }
    allHomeFilteredBanner: { nodes: BannerTypes[] }
    footerLinks: {
      items: string
    }
    allAward: {
      nodes: awardPropType[]
    }
  }
}

const HomeWhatsNewCardImage = '../../assets/images/home-whats-new-card.png'
const HomeDiscoverCardImage1 = '../../assets/images/home-discover-card-1.jpg'
const HomeDiscoverCardImage2 = '../../assets/images/home-discover-card-2.jpg'
const HomeDiscoverCardImage3 = '../../assets/images/home-discover-card-3.jpg'
const HomeDiscoverCardImage4 = '../../assets/images/home-discover-card-4.jpg'

const mainImage = '../../assets/images/mobile-images/banner-images/main.png'
const groupSustainability =
  '../../assets/images/mobile-images/banner-images/group-sustainability.png'

const HomeSustainabilityCardImage1 =
  '../../assets/images/home-sustainability-card-1.png'
const HomeSustainabilityCardImage2 =
  '../../assets/images/home-sustainability-card-2.png'
const HomeSustainabilityCardImage3 =
  '../../assets/images/home-sustainability-card-3.png'
const HomeSustainabilityCardImage4 =
  '../../assets/images/home-sustainability-card-4.png'

const Main = ({
  data: {
    allContent: { nodes: testimonialList },
    allProperty: { nodes: propertyList },
    allHomeFilteredBanner: { nodes: bannerList },
    footerLinks: { items },
    allAward: { nodes: awardsList },
  },
}: MainPropTypes): ReactElement => {
  const featuredProperty = propertyList.filter((property) => property?.featured)
  const [projectsPanelData] = useState(featuredProperty)
  const [closeModal, setCloseModal] = useState(true)

  const isBrowser = typeof window !== 'undefined'
  const [screenwidth, setscreenwidth] = useState(
    isBrowser ? window.screen.width : 0,
  )

  const [banners] = useState(bannerList)
  const [testimonials, setTestimonials] = useState(
    testimonialList.filter((type) => type.category === 'destinations'),
  )

  const settings = {
    // eslint-disable-next-line no-nested-ternary
    slidesToShow: screenwidth > 1200 ? 3 : screenwidth > 800 ? 2 : 1,
    dots: true,
    prevArrow: <FiChevronLeft className="slick-arrow" />,
    nextArrow: <FiChevronRight className="slick-arrow" />,
    // eslint-disable-next-line react/display-name
    customPaging: (i: number): JSX.Element => {
      return <span>{i + 1}</span>
    },
  }

  useEffect(() => {
    if (sessionStorage.getItem('close')) {
      setCloseModal(true)
    } else {
      setCloseModal(false)
    }
  }, [])

  const handleClick = (): void => {
    sessionStorage.setItem('close', 'true')
    setCloseModal(true)
  }

  useEffect(() => {
    setTestimonials(
      testimonialList.filter((type) => type.category === 'destinations'),
    )
  }, [testimonialList])

  // eslint-disable-next-line no-console
  console.log(`Website Version: 1.0.18`)
  return (
    <Layout footerData={items}>
      <Seo
        title="Amaia Land | Affordable Condo & House and Lot Developer"
        jsonData={{
          keywords:
            'properties, condo, condominium, Condominium, philippines, house and lot, House and Lot, amaia, apartment, lots, lots',
        }}
      />

      <Banner banners={banners} projectsPanelData={projectsPanelData} />

      <div className="main-container">
        {/* {!closeModal ? (
          <CustomDiv onClose={() => handleClick()}>
            <Link to="https://access.ayalaland.com/" className="access-ayala">
              <button
                className="access-ayala-close-button"
                onClick={(e) => {
                  e.preventDefault()
                  handleClick()
                }}
              >
                <span>&times;</span>
              </button>
              <img src={AccessAyalaModal} alt="" />
            </Link>
          </CustomDiv>
        ) : null} */}
        <section className="our-properties-section">
          <header>
            <h2>OUR PROPERTIES</h2>
            <p>
              Amaia Land properties offer a range of options that cater to
              various preferences, promising a secure and comfortable living
              experience.
            </p>
          </header>
          <div className="properties-links">
            <div className="properties-links-content">
              <div>
                <img src={PropertyH1} alt="" />
                <img src={PropertyH2} alt="" />
              </div>
              <Link to="/house-lot/">View All House And Lot Properties</Link>
            </div>
            <div className="properties-links-content">
              <div>
                <img src={Condo1} alt="" />
                <img src={Condo2} alt="" />
              </div>
              <Link to="/condominium/">View All Condominium Properties</Link>
            </div>
          </div>
        </section>
        <section className="sustainablity-section">
          <div className="detail-container">
            <h2>
              Quality <strong>&</strong> Sustainable Development
            </h2>
            <p>
              In our mission to serve aspiring Filipino homeowners with
              affordable yet quality homes, we commit ourselves in building
              sustainable communities that support comfortable lives today and
              in many years to come.
            </p>
            <div className="detail-container-mobile-images">
              <StaticImage
                src={groupSustainability}
                className="group-sustainability-img"
                placeholder="blurred"
                alt="group-sustainability"
              />
            </div>
            <LinkButton to="sustainability" primary>
              View Sustainability
            </LinkButton>
            <LinkButton to="about-us">About Amaia</LinkButton>
          </div>
          <div className="preview-container">
            <StaticImage
              src={HomeSustainabilityCardImage1}
              className="preview-img"
              placeholder="blurred"
              alt="sustainability preview image"
            />
            <div className="middle">
              <StaticImage
                src={HomeSustainabilityCardImage2}
                className="preview-img"
                placeholder="blurred"
                alt="sustainability preview image"
              />
              <StaticImage
                src={HomeSustainabilityCardImage3}
                className="preview-img"
                placeholder="blurred"
                alt="sustainability preview image"
              />
            </div>
            <StaticImage
              src={HomeSustainabilityCardImage4}
              className="preview-img"
              placeholder="blurred"
              alt="sustainability preview image"
            />
          </div>
        </section>
        <section className="article-section">
          <div className="main-testimonial-container">
            <TestimonialList testimonials={testimonials} />
          </div>
          <div className="detail-container">
            <div className="main-whats-new">
              <div className="content">
                <h2>
                  What&apos;s <strong>New</strong>
                </h2>
                <h2 className="mobile-title-what">What&apos;s</h2>
                <h2 className="mobile-title">NEW</h2>
                <p>Latest updates and promos</p>
                <LinkButton to="/news-and-events/">View All</LinkButton>
              </div>
              <StaticImage
                src={HomeWhatsNewCardImage}
                className="whats-new-img"
                placeholder="blurred"
                alt="whats-new-card"
              />
            </div>
            <div className="discover">
              <div className="images">
                <StaticImage
                  src={HomeDiscoverCardImage1}
                  className="discover-img"
                  placeholder="blurred"
                  alt="discover-img"
                />
                <StaticImage
                  src={HomeDiscoverCardImage2}
                  className="discover-img"
                  placeholder="blurred"
                  alt="discover-img"
                />
                <StaticImage
                  src={HomeDiscoverCardImage3}
                  className="discover-img"
                  placeholder="blurred"
                  alt="discover-img"
                />
                <StaticImage
                  src={HomeDiscoverCardImage4}
                  className="discover-img"
                  placeholder="blurred"
                  alt="discover-img"
                />
              </div>
              <div className="content">
                <h2>
                  <strong>Discover</strong>the ideal neighborhood for you
                </h2>
                <LinkButton to="/our-properties/" primary>
                  Explore our Neighborhood
                </LinkButton>
              </div>
              <div className="images-mobile">
                <div className="image-mobile-wrapper">
                  <StaticImage
                    src={HomeDiscoverCardImage1}
                    className="image-mobile-img"
                    placeholder="blurred"
                    alt="discover-img-1"
                  />
                  <StaticImage
                    src={HomeDiscoverCardImage2}
                    className="image-mobile-img"
                    placeholder="blurred"
                    alt="discover-img-2"
                  />
                </div>
                <div className="image-mobile-wrapper">
                  <StaticImage
                    src={HomeDiscoverCardImage3}
                    className="image-mobile-img"
                    placeholder="blurred"
                    alt="discover-img-3"
                  />
                  <StaticImage
                    src={HomeDiscoverCardImage4}
                    className="image-mobile-img"
                    placeholder="blurred"
                    alt="discover-img-4"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="awards-section">
          <header>
            <h2>Awards</h2>
            <p>
              Amaia Land&prime;s extensive list of awards and its commitment to
              providing quality and sustainable homes stand as a testament to
              its dedication to enhancing the living standards of Filipinos.
            </p>
          </header>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Slider {...settings} className="awards-slider">
            {awardsList.map(({ year, organization, award, image, project }) => {
              return (
                <div className="awards-item" key={project}>
                  <div className="awards-item-details">
                    <span>{year}</span>
                    <span className="project">{project}</span>
                    <span>{award}</span>
                    <span>{organization}</span>
                    <img src={image} alt="Slider item" />
                  </div>
                </div>
              )
            })}
          </Slider>
        </section>
      </div>
    </Layout>
  )
}

export default Main

export const pageQuery = graphql`
  query {
    allContent {
      nodes {
        ...SustainabilityFields
      }
    }
    allAward {
      nodes {
        award
        image
        organization
        year
        project
      }
    }
    footerLinks {
      items
    }
    allProperty {
      nodes {
        ...PropertyPageFields
      }
    }
    allHomeFilteredBanner {
      nodes {
        ...BannerFields
      }
    }
  }
`
