import React, { ReactElement, useState, useEffect } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import './TestimonialList.scss'
import circle from '../../assets/images/oval.webp'

export type TestimonialTypes = {
  category: string
  title: string
  shortDescription: string
  subtitle: string
  id: string
  image?: {
    url: string
  }
}

type TestimonialListPropTypes = {
  testimonials: TestimonialTypes[]
}

const TestimonialList = ({
  testimonials,
}: TestimonialListPropTypes): ReactElement => {
  const [limitTestimonials] = useState(testimonials.splice(0, 3))

  const [selected, setSelected] = useState<TestimonialTypes>(
    limitTestimonials[0],
  )

  useEffect(() => {
    setSelected(limitTestimonials[0])
  }, [limitTestimonials])

  return (
    <div className="testimonial-container">
      <div className="image-container">
        <div className="circle-overlay">
          <img src={circle} alt="circleOverlay" />
        </div>
        <div className="overlay" />
        <LazyLoadImage
          src={selected?.image?.url}
          placeholder={<img id="placeholder" alt="testimonial placeholder" />}
          alt="testimonialImage"
          className="image"
        />
      </div>
      <div className="testimonial">
        <h2>{selected?.title}</h2>
        <p>{selected?.shortDescription}</p>
        <span className="author">{selected?.subtitle}</span>
      </div>
      <ul className="nav">
        {limitTestimonials.map((testimonial) => (
          <li key={testimonial.id}>
            <button
              type="button"
              className={selected?.id === testimonial?.id ? 'active' : ''}
              onClick={(): void => setSelected(testimonial)}
            >
              &nbsp;
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default TestimonialList
